<template>
  <button ref="btn" :disabled="disabled" :class="{disabled:disabled}" class="uploader-btn" @click="selectfile">
    <slot></slot>
  </button>
</template>

<script>
const COMPONENT_NAME = 'uploader-btn';

export default {
  name: COMPONENT_NAME,
  props: {
    directory: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    attrs: {
      type: Object,
      default() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectfile() {
      this.$emit('select-file');
    }
  }
};
</script>

<style>

</style>

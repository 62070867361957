<template>
  <div ref="button" :class="classes" class="button" @click="handleClick" v-text="text"></div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
export default {
  name: 'StButton',
  props: {
    text: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    handleClick: function handleClick(evt) {
      if (this.disabled) {
        return false;
      }
      this.$emit('click', evt);
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass">
.button {
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  &.small {
    width: 110px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    font-family: PingFangSC-Semibold, sans-serif;
    font-size: 16px;
    &.red {
      color: #ffffff;
      letter-spacing: 0;
      background-image: linear-gradient(-90deg, #ff9090 0%, #ee1a1a 88%);
      box-shadow: 0 4px 13px 0 rgba(238, 26, 26, 0.29);
    }
    &.white {
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0 4px 13px 0 rgba(179, 179, 179, 0.29);
      border-radius: 100px;
    }
  }
  &.disabled {
    opacity: 0.4;
  }
}
</style>

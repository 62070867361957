<template>
  <div class="no-data-default">
    <svg-icon v-if="type" :icon-class="iconClass"></svg-icon>
    <img v-else class="img" src="./default.png" />
    <p class="no-content">{{ text || $t('common.no_data') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Nodata',
  props: {
    // nodata / error
    type: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconClass() {
      let icon = '';
      switch (this.type) {
        case 'nodata':
          icon = 'no-data'
          break;
        case 'error':
          icon = 'update-data'
          break;
        default:
          break;
      }
      return icon;
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.no-data-default {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .svg-icon {
    width: 160px;
    height: 130px;
  }
  .img {
    width: 194px;
    height: 108px;
  }
  .no-content {
    margin-top: 14px;
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #606060;
  }
}
</style>

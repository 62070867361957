<!-- 图片预览 -->
<template>
  <transition name="fade">
    <div class="img-view" @click="bigImg">
      <!-- 遮罩层 -->
      <div class="img-layer"></div>
      <div class="img">
        <img :src="imgSrc">
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['imgSrc'],
  methods: {
    bigImg(e) {
      this.$emit('clickImg');
      e.stopPropagation();
    }
  }
};

</script>
<style lang='scss' rel="stylesheet/sass" scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all .2s linear;
        opacity: 1;
    }
    .fade-enter, .fade-leave-active {
        opacity: 0;
    }

    .img-view {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        left: 0;
        top: 0;
        cursor: zoom-out;
        .img-layer {
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.7);
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        .img img {
            max-width: 90vw;
            max-height: 90vh;
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>

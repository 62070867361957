<template>
  <div class="uploader_container">
    <uploader
      ref="uploader"
      :file-status-text="statusText"
      :video-id="videoId"
      :name="name"
      :re-upload="reUpload"
      :disabled="disabled"
      :min-ratio="minRatio"
      class="uploader-example"
      @upload-start="uploadStart"
      @file-success="fileSuccess"
      @file-removed="fileRemoved"
      @file-error="fileError"
      @file-progress="fileProgress"
    >
    </uploader>
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import uploader from './subComponent/uploader.vue';

export default {
  name: 'StUploadFile',
  components: {
    uploader
  },

  props: {
    name: { type: String, default: 'File upload' },
    reUpload: { type: Boolean, default: false },
    // 是否已有视频Id
    videoId: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    // 最小宽高比
    minRatio: { type: Number, default: null }
  },
  data() {
    return {
      temp: {},
      star: false,
      statusText: {
        success: '成功了',
        error: '出错了',
        uploading: '上传中',
        paused: '暂停中',
        waiting: '等待中'
      },
      isComplete: false
    };
  },
  methods: {
    fileProgress() {
      this.$emit('uploadState', 'uploading');
    },
    fileError(err) {
      this.$emit('uploadError', 'error', err.message);
    },
    fileRemoved() {
      this.$emit('uploadState', 'init');
    },
    uploadStart() {
      this.$emit('uploadState', 'star');
    },
    fileSuccess(file, md5, message) {
      this.$emit('uploadState', 'success');
      this.$emit('success', file, md5, message);
    },
    fileAdded(file) {
      this.$emit('fileAdded', file);
    },
    startUpload(files) {
      this.$refs.uploader.startUpload(files);
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
</style>

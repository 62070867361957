<template>
  <div class="uploader-file">
    <!--S 上传中-->
    <div class="uploading-wrap">
      <p class="progress-percent">{{ progressStyle.progress }}</p>
      <div class="progress-wrap">
        <div class="progress-container">
          <div :style="progressStyle" class="uploader-file-progress"></div>
        </div>
        <div class="uploader-file-actions">
          <span class="uploader-file-remove" @click="remove"></span>
        </div>
      </div>

      <p class="file-name">{{ file.name }}</p>
      <p>
        <span>{{ (loaded / 1024 / 1024).toFixed(1) }}MB/{{ (file.size / 1024 / 1024).toFixed(1) }}MB</span>
      </p>
      <!-- <p><span>{{(currentSpeed/1024).toFixed(2)}}KB/S</span></p> -->
    </div>
    <!--E 上传中-->
  </div>
</template>
<script>
const COMPONENT_NAME = 'uploader-file';
export default {
  name: COMPONENT_NAME,
  props: {
    file: {
      type: File,
      default() {
        return {};
      }
    },
    loaded: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    progressStyle() {
      const progress = Math.floor((this.loaded / this.file.size) * 100);
      const style = 'translateX(' + Math.floor(progress - 100) + '%)';
      return {
        progress: progress + '%',
        webkitTransform: style,
        mozTransform: style,
        msTransform: style,
        transform: style
      };
    }
  },
  methods: {
    remove() {
      this.$emit('remove');
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.uploader-file {
  text-align: center;
  width: 525px;
  margin: 0 auto;
  .uploading-wrap {
    margin-top: 80px;
    margin-bottom: 140px;
  }
}
.uploader-file[status="success"] .uploading-wrap {
  display: none;
}

.progress-percent {
  text-align: center;
  font-family: PingFangSC-Semibold, sans-serif;
  font-size: 30px;
  color: #4a4a4a;
  margin-bottom: 20px;
  line-height: 1;
}

.file-name {
  font-family: PingFangSC-Regular, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  letter-spacing: 0;
  margin-top: 20px;
  text-align: left;
}

.progress-wrap {
  overflow: hidden;
}

.video-wrap {
  width: 400px;
  position: relative;
  .play-icon {
    background: url("~@/views/post/image/play.png") no-repeat 0 0;
    width: 41px;
    height: 41px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.progress-container {
  float: left;
  width: 500px;
  height: 10px;
  background: #ebebeb;
  border-radius: 100px;
  z-index: -1;
  overflow: hidden;
  .uploader-file-progress {
    width: 500px;
    height: 10px;
    background-image: linear-gradient(#ff9696, #ee1a1a);
    border-radius: 100px;
    transform: translateX(-100%);
  }
}

.uploader-file-progressing {
  transition: all 0.4s linear;
}

.uploader-file-info {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: hidden;
}

.uploader-file-info:hover {
  background-color: rgba(240, 240, 240, 0.2);
}

.uploader-file-name {
  width: 45%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-indent: 14px;
}

.uploader-file-actions > span {
  float: left;
  width: 14px;
  height: 14px;
  margin-left: 10px;
  cursor: pointer;
  background: url("../image/close.png") no-repeat 0 0;
}

.uploader-file-actions .uploader-file-remove {
  display: block;
}
</style>

<template>
  <div v-loading="loading" class="uploadPictrue_wrapper">
    <div v-show="showCrappa && hideUploadCover">
      <div class="uploadPicture_box">
        <div class="uploadPictrue">
          <croppa
            ref="cropper"
            :img="image"
            :auto-crop="true"
            :info="updParams.info"
            :full="updParams.full"
            :auto-crop-width="autoCropWidth"
            :auto-crop-height="autoCropHeight"
            :center-box="updParams.centerBox"
            :fixed-box="updParams.fixedBox"
            :fixed="updParams.fixed"
            :fixed-number="updParams.fixedNumber"
            :original="false"
            :output-type="type"
            :output-size="quality"
            @realTime="realTime"
          />
        </div>
        <div class="uploadPictrue_preview">
          <img ref="previewCoverImg" :src="previewCover" class="previewCover" />
          <img v-if="updParams.from && updParams.from === 'avatar'" ref="previewCoverImg" :src="previewCover" class="previewCover avatar" />
          <a class="reupload" @click="chooseFile()">Reupload</a>
        </div>
      </div>
      <div class="uploadCtrl">
        <el-button type="primary" @click="submit()">Submit</el-button>
        <el-button @click="cancel($event)">Cancel</el-button>
      </div>
    </div>
    <input ref="fileInput" :accept="accept" type="file" style="height:1px;width:1px;overflow:hidden;margin-left:-99999px;position:absolute;" @change="_handleInputChange" />
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import { VueCropper } from 'vue-cropper';
import { getToken, coverCheckExsit } from '@/api/upload';
var SparkMD5 = require('spark-md5');
import Base64 from 'base-64';
import uploadToS3 from '@/utils/aws';
export default {
  name: 'StUploadPicture',
  components: {
    'croppa': VueCropper
  },
  props: {
    sizeLimit: {
      type: Number,
      default: 50 * 1024 * 1024 // 图片大小限制
    },
    hideUploadCover: {
      type: Boolean,
      default: false
    },
    autoCropWidth: {
      type: Number,
      default: 384
    },
    autoCropHeight: {
      type: Number,
      default: 216
    },
    params: {
      type: Object,
      default: null
    },
    // 是否进行查重
    checkExsit: {
      type: Boolean,
      default: false
    },
    // 图片分辨率校验
    checkResolution: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      image: '', // 图片
      accept: '.jpeg,.png,.jpg,.gif,.bmp',
      showCrappa: true,
      previewCover: '',
      quality: 1, // 图片质量
      type: 'jpeg' // 图片类型 jpg png
    };
  },
  computed: {
    // 组件截取组件配置
    updParams() {
      return Object.assign({ full: true, fixedBox: true, fixed: true, info: false, fixedNumber: [1, 1], centerBox: true }, { ...this.params });
    }
  },
  methods: {
    chooseFile() {
      if (this.passive) return;
      this.$refs.fileInput.click();
    },
    getChosenFile() { // 选择图片
      return this.$refs.fileInput.files[0];
    },
    beginUpload() { // 点击upload调用
      this.showCrappa = true; // 显示裁图
      this.$emit('hideOther', true); // 通知父级隐藏图片和文字
      this.chooseFile(); // 选取文件
    },
    realTime(data) { // 监听预览
      const that = this;
      this.$refs.cropper.getCropData((data) => {
        that.previewCover = data;
      });
    },
    cancel() {
      this.$refs.fileInput.value = '';
      // if (!event._constructed) {
      //   return;
      // }
      this.image = '';
      this.previewCover = '';
      this.$refs.cropper.reload();
      this.$emit('setClose');
    },
    async submit() { // 上传
      // 文件校验
      const flag = await this.checkImg();
      if (!flag) return;
      Promise.all([this._cropperUpload()]).then(() => {
        this.loading = false;
        this.cancel();
      }).catch(() => {
        this.loading = false;
      })
    },
    // 图片校验
    async checkImg() {
      const that = this;
      if (this.checkResolution) {
        const naturalWidth = this.$refs.previewCoverImg.naturalWidth;
        const naturalHeight = this.$refs.previewCoverImg.naturalHeight;
        // 图片分辨率校验
        if (naturalWidth < 240 || naturalHeight < 240) {
          this.$message({
            center: true,
            type: 'error',
            message: 'Please upload cover clearer than 240p.',
            duration: 10000,
            showClose: true
          });
          return false;
        }
      }
      return new Promise((resolve, reject) => {
        that.$refs.cropper.getCropBlob((blob) => {
          // 图片size校验
          if (blob.size > that.sizeLimit) {
            that.$message({
              center: true,
              type: 'error',
              message: 'Please upload cover smaller than 50Mb.',
              duration: 10000,
              showClose: true
            });
            resolve(false);
            return;
          }
          // 图片md5校验
          if (that.checkExsit) {
            var spark = new SparkMD5.ArrayBuffer();
            let md5 = null;
            const md5FileReader = new FileReader();
            md5FileReader.onload = function(e) {
              md5 = e.target.result;
              spark.append(md5);
              md5 = spark.end();
              coverCheckExsit({ md5: md5 }).then(res => {
                res = res.data;
                if (res.code === 0) {
                  if (res.data) {
                    resolve(true);
                  } else {
                    that.loading = false;
                    that.$message({
                      center: true,
                      type: 'error',
                      message: 'The cover had been used in other video and you may try another one.',
                      duration: 10000,
                      showClose: true
                    });
                    resolve(false);
                  }
                }
              });
            };
            md5FileReader.readAsArrayBuffer(blob);
          } else {
            resolve(true);
          }
        })
      })
    },
    _originUpload() {
      return new Promise((resolve, reject) => {
        if (this.updParams.from === 'avatar') {
          resolve();
          return;
        }
        this.loading = true;
        const file = this.getChosenFile();
        const fileName = file.name;
        const param = {
          fileName: 'origin_' + fileName,
          fileSize: file.size
        };
        Object.assign(param, this.params);
        getToken(param).then((res) => {
          res = res.data;
          if (res.code === 0) {
            this.uploadFile(res.data, file, function() {
              resolve();
            });
          } else {
            reject();
          }
        });
      })
    },
    _cropperUpload() {
      return new Promise((resolve, reject) => {
        const that = this;
        const file = that.getChosenFile();
        let fileName = file.name;
        fileName = fileName.replace(/\.\w+$/, '.jpeg')
        this.$refs.cropper.getCropBlob((blob) => {
          this.loading = true;
          const naturalWidth = this.$refs.previewCoverImg.naturalWidth;
          const naturalHeight = this.$refs.previewCoverImg.naturalHeight;
          const param = {
            width: naturalWidth,
            height: naturalHeight,
            fileName: fileName,
            fileSize: blob.size
          };
          Object.assign(param, that.params);
          getToken(param).then((res) => {
            res = res.data;
            if (res.code === 0) {
              that.file = blob;
              that.uploadFile(res.data, blob, function(msg) {
                msg.picUrl = res.data.picUrl;
                that.$emit('success', msg);
                resolve();
              });
            } else {
              reject();
            }
          });
        });
      })
    },
    async uploadFile(data, file, callback) {
      const that = this;
      const md5Base64 = await this._fileMd5Base64(file)
      const params = {
        file: file,
        accessKeyId: data.aid,
        secretAccessKey: data.sid,
        sessionToken: data.st,
        bucket: data.bucketName,
        path: data.path,
        ContentMD5: md5Base64
      }
      that.upload = uploadToS3(params, null, function(msg) {
        if (callback) {
          callback.call(that, msg);
        }
        console.log('uploadimg:' + JSON.stringify(msg));
      }, function(err) {
        that.loading = false;
        that.$message({
          center: true,
          type: 'error',
          message: err.message || 'Upload failed!'
        });
        console.log('uploadimgerror:' + JSON.stringify(err));
      });
    },
    _fileMd5Base64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryFile = SparkMD5.hashBinary(e.target.result, true)
          const md5Base64 = Base64.encode(binaryFile)
          resolve(md5Base64)
        }
        reader.readAsBinaryString(file)
      })
    },
    _handleClick() {
      if (!this.hasImage() && !this.disableClickToChoose && !this.disabled && !this.supportTouch && !this.passive) {
        this.chooseFile();
      }
    },
    _handleInputChange() {
      const file = this.getChosenFile();
      this._onNewFileIn(file);
    },
    _onNewFileIn(file) {
      if (typeof window !== 'undefined' && typeof window.FileReader !== 'undefined') {
        const fr = new FileReader();
        fr.onload = (e) => {
          const fileData = e.target.result;
          this.image = fileData;
        };
        fr.readAsDataURL(file);
      }
    },
    _fileMd5(file) {
      return new Promise((resolve, reject) => {
        var spark = new SparkMD5.ArrayBuffer();
        let md5 = null;
        const md5FileReader = new FileReader();
        md5FileReader.onload = function(e) {
          md5 = e.target.result;
          spark.append(md5);
          md5 = spark.end();
          resolve(md5)
        };
        md5FileReader.readAsArrayBuffer(file);
      })
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
.uploadPicture_box {
  width: 545px;
  height: 384px;
  position: relative;
  margin: 0 auto;
  .uploadPictrue {
    height: 384px;
    width: 384px;
  }
  .croppa-container {
    margin: 82px 0 auto 0;
  }
  .uploadPictrue_preview {
    width: 150px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .reupload {
      cursor: pointer;
      display: block;
      text-align: center;
      font-family: AvenirNext-Medium;
      font-size: 12px;
      color: #4a4a4a;
      text-decoration-line: underline;
    }
  }
}

.uploadCtrl {
  padding-top: 31px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  .cancel {
    margin-left: 22px;
  }
}

.previewCover {
  max-width: 100%;
  max-height: 100%;
  min-width: 100px;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  &.avatar {
    border-radius: 50%;
  }
}
</style>

<!-- upload image chenyh1 -->
<template>
  <div class="upload-image">
    <input ref="input" type="file" accept="image/*">
  </div>
</template>

<script>
import { getToken } from '@/api/upload';
export default {
  name: 'UploadImage',
  props: {
    params: {
      type: Object,
      default: null
    },
    sizeLimit: {
      type: Number,
      default: null // 图片大小限制
    }
  },
  data() {
    return {
      file: ''
    };
  },
  mounted() {
    const that = this;
    this.$refs.input.addEventListener('change', function(e) {
      if (e.target.value) {
        that.addFiles(e.target.files);
        e.target.value = '';
      }
    });
  },
  methods: {
    selectFile() {
      this.$refs.input.click();
    },
    addFiles(files) {
      const that = this;
      const file = files[0];
      if (file) {
        // 图片size校验
        if (that.sizeLimit && file.size > that.sizeLimit) {
          that.$message({
            center: true,
            type: 'error',
            message: 'Image size too large.'
          });
          return;
        }
        that.$emit('start');
        const param = {
          fileName: file.name,
          fileSize: file.size
        };
        Object.assign(param, that.params);
        getToken(param).then((res) => {
          if (res.data.code === 0) {
            that.file = file;
            that.uploadFile(res.data.data);
          } else {
            that.$message({
              center: true,
              type: 'error',
              message: 'Token acquisition failed!'
            });
          }
        });
      }
    },
    uploadFile(data) {
      const that = this;
      const key = data.aid;
      const secret = data.sid;
      const bucket = data.bucketName;
      const path = data.path;
      const st = data.st;
      // eslint-disable-next-line no-undef
      const s3 = new AWS.S3({ accessKeyId: key, secretAccessKey: secret, sessionToken: st, region: 'ap-southeast-1' });

      const params = {
        Bucket: bucket,
        Key: path,
        Body: that.file,
        ACL: 'public-read',
        ContentType: that.file.type
      };
      const upload = s3.upload(params, {
        queueSize: 3,
        leavePartsOnError: true,
        connectTimeout: 60 * 1000 * 10,
        httpOptions: {
          timeout: 60 * 1000 * 10
        }
      });
      upload.send(function(err, msg) {
        if (err) {
          that.$message({
            center: true,
            type: 'error',
            message: 'Upload failed!'
          });
          that.$emit('error', err);
          console.log('uploadimgerror:' + JSON.stringify(err));
        } else {
          msg.picUrl = data.picUrl;
          that.$emit('success', msg);
          console.log('uploadimg:' + JSON.stringify(msg));
        }
      });
    }
  }
};

</script>
<style lang='scss' rel='stylesheet/sass' scoped>
.upload-image {
    visibility: hidden;
    height: 0;
    width: 0;
}
</style>

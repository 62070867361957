<template>
  <div class="popup">
    <el-dialog :visible="showCoverDialog" :append-to-body="true" :width="width" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="beforeCloseDialog" :title="dialogTitle">
      <st-upload-picture ref="uploadPicture" :hide-upload-cover="openCoverUpload" :auto-crop-width="autoCropWidth" :auto-crop-height="autoCropHeight" :params="params" :check-exsit="checkExsit" :check-resolution="checkResolution" @success="handlerSuccess" @setClose="setCloseDialog"></st-upload-picture>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
import { mapGetters, mapActions } from 'vuex';
import uploadPictrue from '../uploadPicture/index';

export default {
  name: 'StUploadCoverPopup',
  components: {
    'st-upload-picture': uploadPictrue
  },
  props: {
    open: { type: Boolean, default: false },
    declare: { type: String, default: '' },
    autoCropWidth: { type: Number, default: 384 },
    autoCropHeight: { type: Number, default: 216 },
    params: { type: Object, default: null },
    dialogTitle: { type: String, default: 'Customized Cover' },
    checkExsit: { type: Boolean, default: false },
    checkResolution: { type: Boolean, default: false }
  },
  data() {
    return {
      width: '600px',
      openCoverUpload: true
    };
  },
  computed: {
    showCoverDialog() {
      return this.uploadCoverDialog;
    },
    ...mapGetters(['clientHeight', 'clientWidth', 'uploadCoverDialog'])
  },
  watch: {
    uploadCoverDialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.uploadPicture.chooseFile();
        });
      }
    }
  },
  methods: {
    beforeCloseDialog(done) {
      this.closeUploadCoverDialog();
      done();
    },
    setCloseDialog() {
      this.closeUploadCoverDialog();
    },
    handlerSuccess(data) {
      this.$emit('success', data);
    },
    ...mapActions(['closeUploadCoverDialog'])
  }
};
</script>

<style lang="scss" rel="stylesheet/sass" scoped>
</style>

<template>
  <div class="uploader">
    <slot :started="started">
      <uploader-btn
        v-show="(!started && !reUpload) || reUpload && showReUploadBtn"
        :disabled="disabled"
        :attrs="attrs"
        @select-file="selectFile"
      >
        {{ name }}
        <input ref="input" :accept="attrs.accept" type="file" class="file-input">
      </uploader-btn>
      <uploader-file v-if="file" :file="file" :list="true" :loaded="loaded" @remove="fileRemoved"></uploader-file>
    </slot>
  </div>
</template>

<script type="text/ecmascript-6" charset="utf-8">
const COMPONENT_NAME = 'uploader';
const FILES_UPLOAD_START = 'uploadStart';
import UploaderBtn from '../subComponent/btn.vue';
import UploaderFile from './file.vue';
import { refresh } from '@/api/login';
import { getAccess } from '@/api/upload';
import makeMd5 from '@/utils/makeMd5';
import uploadToS3 from '@/utils/aws';

export default {
  name: COMPONENT_NAME,
  components: {
    UploaderBtn,
    UploaderFile
  },
  props: {
    reUpload: { type: Boolean, default: false },
    name: { type: String, default: 'File upload' },
    videoId: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    // 最小宽高比
    minRatio: { type: Number, default: null }
  },
  data() {
    return {
      attrs: {
        accept: 'video/mp4'
        //          ['mp4', 'm3u8', 'rmvb', 'avi', 'swf', '3gp', 'mkv', 'flv'] 'image/*'
      },
      started: false,
      showReUploadBtn: true,
      file: '',
      loaded: 0,
      md5: ''
    };
  },
  mounted() {
    const that = this;
    this.$refs.input.addEventListener('change', function(e) {
      if (e.target.value) {
        that.addFiles(e.target.files);
        e.target.value = '';
      }
    });
  },
  methods: {
    startUpload(files) {
      if (files) {
        this.addFiles(files);
      } else {
        this.selectFile();
      }
    },
    kebabCase(s) {
      return s.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
    },
    refreshState(type) {
      const that = this;
      if (type === 'start') {
        that.interval = setInterval(function() {
          refresh();
        }, 5 * 60 * 1000);
      } else {
        clearInterval(that.interval);
      }
    },
    uploadStart() {
      this.started = true;
      this.showReUploadBtn = false;
      this.uploadState = 'star';
      this.$emit(this.kebabCase(FILES_UPLOAD_START));
      this.refreshState('start');
    },
    //  上传完成
    fileSuccess(data) {
      const that = this;
      return function(res) {
        that.showReUploadBtn = true;
        data.res = res;
        that.$emit('file-success', that.file, that.md5, data);
        that.file = '';
        that.refreshState('end');
      }
    },
    //  上传中
    fileProgress(e) {
      this.$emit('file-progress');
      this.loaded = e.loaded;
    },
    fileError(err) {
      this.$emit('file-error', err);
      this.started = false;
      this.file = '';
      this.refreshState('end');
    },
    fileRemoved() {
      this.started = false;
      this.file = '';
      if (this.upload) this.upload.abort();
      this.$emit('file-removed');
    },
    selectFile() {
      this.$refs.input.click();
    },
    async addFiles(files) {
      const that = this;
      const file = files[0];
      // 类型校验
      if (file.type !== 'video/mp4') {
        this.$message({
          message: 'File type error!',
          type: 'error',
          center: true,
          duration: 10000,
          showClose: true
        });
        return;
      } else if (file.size < 1024 * 5) {
        // 文件大小校验
        this.$message({
          message: 'Files need to be larger than 5KB!',
          type: 'error',
          center: true,
          duration: 10000,
          showClose: true
        });
        return;
      } else if (file.size > 1024 * 1024 * 1024 * 10) {
        // 文件大小校验
        this.$message({
          message: 'Upload failed, please upload video ≤10G',
          type: 'error',
          center: true,
          duration: 10000,
          showClose: true
        });
        return;
      }
      const { videoWidth, videoHeight, duration } = await this.getVideoResolution(file);
      // 最小宽高校验 校验小于240P
      if (videoWidth < 240 || videoHeight < 240) {
        this.$message({
          message: `Please upload a video with a resolution of 240P or higher`,
          type: 'error',
          center: true,
          duration: 10000,
          showClose: true
        });
        return;
      }
      // 宽高比校验
      if (this.minRatio !== null) {
        if (videoWidth / videoHeight < this.minRatio) {
          this.$message({
            message: `Upload failed, please upload video image aspect ratio ≥${this.minRatio}`,
            type: 'error',
            center: true,
            duration: 10000,
            showClose: true
          });
          return;
        }
      }
      // 时长校验
      if (duration > 60 * 60 || duration < 5) {
        that.$message({
          message: 'Please upload video more than 5s and & less than 1h.',
          type: 'error',
          center: true,
          duration: 10000,
          showClose: true
        });
        return;
      }

      that.uploadStart();
      that.file = file;
      that.loaded = 0;
      // 文件MD5获取  验重
      // that.md5 = await this.getFileMd5(file);
      // 获取临时token 开始上传
      if (file) {
        const params = { fileName: file.name };
        if (this.videoId) {
          params.vid = this.videoId;
        }
        getAccess(params).then((res) => {
          if (res.data.code === 0) {
            that.uploadFile(res.data.data);
          } else {
            this.$message({
              center: true,
              type: 'error',
              message: res.data.data ? res.data.data : 'Failed to get signature!',
              duration: 10000,
              showClose: true
            });
            this.fileRemoved();
          }
        });
      }
    },
    // 文件上传s3
    uploadFile(data) {
      const that = this;
      that.vid = data.vid;
      const params = {
        file: that.file,
        accessKeyId: data.aid,
        secretAccessKey: data.sid,
        sessionToken: data.st,
        bucket: data.bucketName,
        path: data.path
      }
      that.upload = uploadToS3(params, that.fileProgress, that.fileSuccess(data), that.fileError);
    },
    getVideoResolution(file) {
      return new Promise((resolve, reject) => {
        const videoSrc = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.src = videoSrc;
        video.addEventListener('canplay', function() {
          resolve({ videoWidth: this.videoWidth, videoHeight: this.videoHeight, duration: this.duration });
        })
      })
    },
    getFileMd5(file) {
      return new Promise((resolve, reject) => {
        makeMd5(file).then(res => {
          resolve(res);
        });
      })
    }

  }

};
</script>

<style>
    .uploader {
        position: relative;
    }
    .file-input {
      visibility: hidden;
      width: 0;
    }
</style>
